@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Karla', sans-serif;
}
.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: stretch;
  position: relative;
}
.login__textBox {
  padding: 15px;
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #581669;
  border-radius: 8px;
}

.onboarding__textBox {
  padding: 15px;
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #581669;
  border-radius: 8px;
  background-color: #fdece6;
  color: #581669;
}
.onboarding__textBox_error {
  padding: 15px;
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #581669;
  border-radius: 8px;
  background-color: #fdece6;
  color: red;
}
.onboarding__date {
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #67e895;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
}
.onboarding__dropdown {
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #67e895;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
}
.onboarding__interest {
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #67e895;
  border-radius: 18px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.onboarding__selected_interest {
  background-color: #67e895;
  color: #7026d5;
}
.onboarding__unselected_interest {
  background-color: rgba(0, 0, 0, 0);
  color: 'white';
}
.onboarding__gender {
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #67e895;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  width: 260px;
  height: 50px;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
}
.onboarding__selected_gender {
  background-color: #67e895;
  color: #7026d5;
}
.onboarding__unselected_gender {
  background-color: rgba(0, 0, 0, 0);
  color: 'white';
}
.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: #67e895;
  width: 160px;
  border-radius: 20px;
}
.onboarding__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: #7026d5;
  background-color: #67e895;
  width: 160px;
  border-radius: 20px;
  font-weight: bold;
}
.onboarding__alt__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #67e895;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  width: 160px;
  border-radius: 20px;
  font-weight: bold;
}
.login__google {
  background-color: #4285f4;
}
.login div {
  margin-top: 7px;
}
.onboarding-footer {
  height: 50px;
  margin-top: -50px;
}

div.shake {
  -webkit-animation: shake 1s infinite;
          animation: shake 1s infinite;
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
            transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-2deg);
            transform: translate(-1px, -2px) rotate(-2deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(3deg);
            transform: translate(-3px, 0px) rotate(3deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
            transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-2deg);
            transform: translate(2px, 1px) rotate(-2deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(4deg);
            transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
            transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
            transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-2deg);
            transform: translate(-1px, -2px) rotate(-2deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(3deg);
            transform: translate(-3px, 0px) rotate(3deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
            transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-2deg);
            transform: translate(2px, 1px) rotate(-2deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(4deg);
            transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
            transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

